import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import drone from "../../Assets/Projects/drone.png";
import greenhouse from "../../Assets/Projects/greenhouse.png";
import implaza from "../../Assets/Projects/implaza.png";
import factory from "../../Assets/Projects/factory.png";
import dcbot from "../../Assets/Projects/dcbot.png";
import medrush from "../../Assets/Projects/medrush.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      {/* <Particle /> */}
      <Container>
        <h1 className="project-heading">
          My <strong className="blue">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={factory}
              isBlog={false}
              title="Factory monitoring System - IoT"
              description="Developed a comprehensive solution for utilizing Advantage Digital I/O Module to detect metal parts within glove containers(Project recieved to ROTEC Automation pvt Ltd). Integrated with PLC for real-time monitoring and leveraged a self-hosted MQTT broker on a Linux server for efficient communication. Additionally, created a user-friendly web dashboard enabling stakeholders to track live status updates and access historical data on errors and outages."
              ghLink="https://github.com/akilaid/rotec"
            // demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={medrush}
              isBlog={false}
              title="Final Year Project - Medicine Dispenser"
              description="The Automatic Medicine Dispenser project aims to tackle medication non-adherence by developing an advanced system for patients with chronic conditions. This innovative dispenser securely stores and dispenses medications at scheduled intervals, enhancing adherence and health outcomes. It also offers comprehensive features like tracking adherence, generating reports, and safety mechanisms, promising to simplify medication management for all stakeholders."
              ghLink="https://github.com/akilaid/MedRush-NodeMCU"
            // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={greenhouse}
              isBlog={false}
              title="IoT-Based Greenhouse Remote Monitoring System"
              description="Developed a Greenhouse Monitoring and Control System utilizing ESP32 technology. This system allows for the real-time monitoring of environmental parameters such as lux level, air humidity, soil moisture, water level, and temperature within the greenhouse. Additionally, it enables remote control of greenhouse equipment via an Android app, with communication facilitated through Firebase integration."
              ghLink="https://github.com/akilaid"
            // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={implaza}
              isBlog={false}
              title="Self-Managed Hosting Service"
              description="Managing a web-hosting service that accommodates diverse projects and websites. Operating on a Linux server, my role involves overseeing the self-managed infrastructure, ensuring smooth operation, and providing support when needed. Additionally, I handle tasks such as configuring the code editor, organizing files in the file manager, and managing databases effectively. My objective is to maintain a reliable hosting environment that caters to the needs of various projects and websites."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://implaza.tech"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={drone}
              isBlog={false}
              title="Advanced drone"
              description="Built an advanced drone utilizing Ardupilot v2. This cutting-edge drone features a camera and GPS functionality, enabling precise navigation and aerial imaging. Additionally, it is equipped with the capability to execute pre-planned routes autonomously, offering enhanced maneuverability and operational efficiency. Whether for aerial surveillance, mapping, or other applications, this drone stands as a testament to innovation and technological advancement in unmanned aerial systems."
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://youtu.be/hJ9r8gLDU5c"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dcbot}
              isBlog={false}
              title="COD4x RCON Discord Bot"
              description="This Bot facilitates seamless server management on the  COD4 server, offering administrators the ability to control map changes and gametypes effortlessly. With the ezAdmin role, users can utilize commands like !ez map and !ez gametype to switch between maps and gametypes . This tool enhances server operation, ensuring a smoother gaming experience for all players."
              ghLink="https://github.com/akilaid/ezdcbot"
            // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>


        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
