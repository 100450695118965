import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        {/* <Particle /> */}
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'm
                <strong className="main-name"> Akila Indunil</strong>
              </h1>

              {/* <div style={{ padding: 5, textAlign: "left" }}>
                <Col md={12} style={{ paddingBottom: 2 }}>
                  <img
                    src={homeLogo}
                    alt="home pic"
                    className="img-fluid"
                    style={{ maxHeight: "450px" }}
                  />

                </Col>
              </div> */}

            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>

              <h1 style={{ fontSize: "2.6em" }}>
                Let me <span className="blue"> introduce </span> myself
              </h1>
              <p className="home-about-body">
              I'm an Electrical and Electronic Engineering Technology undergraduate passionate about exploring the intricacies of Linux and Windows systems. I find joy in fine-tuning configurations and optimizing performance for seamless functionality.
                <br />
                <br />But that's just the beginning. My true passion lies in the dynamic realms of <b className="blue">Internet of Things (IoT)</b> and <b className="blue">Automation</b>. I'm fascinated by merging the physical and digital worlds, crafting custom sensor networks, and developing intelligent control systems to enhance efficiency, convenience, and sustainability.
                <br />
                <br />As an undergraduate, I'm not just studying existing technologies—I'm shaping the future of connected devices and automated systems. My coursework and projects build a solid foundation in circuit design, digital electronics, and programming, empowering me to make a tangible impact in the world.
              </p>

            </Col>

          </Row>
        </Container>
      </Container>
      {/* <Home2 /> */}
    </section>
  );
}

export default Home;
