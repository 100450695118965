import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="blue">Akila Indunil </span>
            from <span className="blue"> Bandarawela, Sri Lanka.</span>
            <br />
            <br />
            I am currently looking for a occupation in the field of <span className="blue">IoT</span> and <span className="blue">Automation</span>.
            <br />
            <br />
            I have completed my Degree in <span className="blue">Electrical and Electronic Engineering Technology</span> at Rajarata University of Sri Lanka. (pending results)
            <br />
            <br />
            Here are some activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> DIY IoT projects
            </li>
            <li className="about-activity">
              <ImPointRight /> Reverse Engineering stuff
            </li>
            <li className="about-activity">
              <ImPointRight /> Gaming
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
